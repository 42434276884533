<template>
<div >
    <Form ref="companyQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
        <Row :gutter="20">
            <i-col span="8">
            <FormItem prop="companyName" label="公司名称:" style="margin-bottom:5px;margin-top:10px">
                <Select v-model="formInline.companyidx" @on-open-change="onopenchange">
                  <Option v-for="(item,index) in companylist" :value="index" :key="index">{{item.companyname}}</Option>
                </Select>
            </FormItem>
            </i-col>
            <i-col span="8">   
            <FormItem style="margin-bottom:5px;margin-top:10px">
                <Button type="primary" @click="handleAdd()" >点击开通功能</Button>    
            </FormItem>             
            </i-col>
        </Row>
    </Form>
    
    <!-- 整车编码列表-->
    <div style="padding-top:10px">
        <Table border  :columns="tblcolumns" :data="tbldata">
        <template slot-scope="{ row, index }" slot="companyname">
            <strong>{{ row.companyname }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
            <p v-if="row.status ==0">未验厂</p>
            <p v-else>验厂完毕</p>
        </template>
        <template slot-scope="{ row, index }" slot="action" >
            <Button type="error" size="small" @click="remove(index)" style="margin-right: 5px">移除</Button>
        </template>
        </Table>
    </div>
</div>
</template>

<script>
export default {
    name:'CheckCompany',
    data () {
            return {
                formInline: {
                    companyidx:null
                },
                companylist:[],
                tblcolumns: [
                    {
                        title: '公司名称',
                        slot: 'companyname',
                    },
                    {
                        title: '公司编码',
                        key: 'companycode',
                    },                 
                    {
                        title: '状态',
                        slot: 'status',
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 280,
                        align: 'left',
                        fixed: 'right'
                    }
                    
                ],
                tbldata: [],
                modifyindex:null
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            queryCompanylist(){
                this.$axios({
                    method:'post',
                    url:"/api/adminuser/listall",
                    data:null,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data

                        this.companylist =rspdata
                        console.log(this.companylist)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));                   
            },
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/yccompany/company/query",
                    data:null,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data

                        this.tbldata =rspdata
                        console.log(this.tbldata)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            handleAdd() {
                if(this.formInline.companyidx == null || this.formInline.companyidx == ''){
                    this.$Message.error({content:'请选择需要开通的公司', duration:3})
                    return
                }
                this.$axios({
                    method:'post',
                    url:"/api/yccompany/company/addnew",
                    data:{
                        companyid: this.companylist[this.formInline.companyidx].id,
                        companyname: this.companylist[this.formInline.companyidx].companyname,
                        companycode: this.companylist[this.formInline.companyidx].companycode
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            remove (index) {  
                this.$Modal.confirm({
                content:'确定要关闭该公司权限吗？',
                onOk:()=>{
                  this.handleRemove(index)
                }
              })
            },
            handleRemove(index){
                this.$axios({
                    method:'post',
                    url:"/api/yccompany/company/delete",
                    data:{
                        companyid: this.tbldata[index].id,
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onopenchange(action){
                console.log(action)

                if(action){
                    if(this.companylist.length == 0){
                        this.queryCompanylist()
                    }
                }
            }
        },
        components:{
        }
}
</script>
<style>
    .ivu-table .demo-table-warning-row td{
        background-color: #e7cac6;
        color: #fff;
    }
</style>